<template>
	<div >
		<v-card v-if="Optional==0">
			<v-card style="margin-top: 15px; margin-bottom: 10px;">
				<v-card-text
					style="height: 40px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
				>
					<b>Abastecimiento por QR</b>
					
				</v-card-text>
				<v-row style="margin: auto">
					<v-col cols="12">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@onValue="onValue($event)"
							@onData="onData($event)"
							:readonly="false"
						></s-scanner-qr>
					</v-col>
					<v-col cols="12"  xs="4" sm="4" md="4" v-if="this.$fun.getSecurity().IsLevelAdmin">
						<s-switch label="Abast. Manual" v-model="Optional"></s-switch>
					</v-col>
				</v-row>
			</v-card>

			<v-card flat style="margin-top: -8px; margin-bottom: 5px;">
				<v-row style="margin: auto" justify="center">
					<v-col cols="6" lg="3" md="3">
						<s-select-definition
							:def="1257"
							label="Calibradora"
							style=" border-radius: 8px;"
							v-model="TypeCalibrator"
						/>
					</v-col>
					<v-col cols="6" lg="3" md="3">
						<s-select-definition
							label="Tina"
							:def="1258"
							v-model="TypeTub"
						/>
					</v-col>
					<v-col cols="12" lg="3" md="3">
						<s-text
							label="Cantidad"
							v-model="LcsAmountSupply"
							outlined
							number
							autofocus
							readonly
						/>
					</v-col>
				</v-row>
			</v-card>

			<s-crud
				title="Abastecimiento - Calibrado"
				:filter="filter"
				:config="config"
				@rowSelected="rowSelected($event)"
				ref="gridCalibratedSupply"
				:mobile="1024"
				height="auto"
				noToolbar
				noFooter
			>
				<template v-slot:Options="{ row }">
					<div style="display: flex;">
						<!-- <v-btn
							x-small
							:color="row.LcsID > 0 ? 'warning' : colorSuccess"
							:height="40"
							dark
							rounded
							shaped
							style="border-radius: 13px; margin: 10px 10px 10px 10px;"
							
							>{{
								row.LcsID > 0
									? "Terminar Abastecimiento"
									: "Abastecer Fruta: "
							}} -->
							<b>
								<v-chip style="margin: 0px" small :color="'error'">
									{{ AmountJabasWeigh }}
								</v-chip>

								<!-- @click="clickInitial(row)" + AmountJabasWeigh -->
							</b>
							<!-- <v-icon style="font-size: 16px !important">fas fa-eye</v-icon> -->
						<!-- </v-btn> -->
					</div>
				</template>
			</s-crud>
		</v-card>

		<v-card v-if="Optional==1">
			<v-card style="margin-top: 15px; margin-bottom: 10px;">
				<v-card-text
					style="height: 40px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
				>
					<b>Abastecimiento Manual</b>
					
				</v-card-text>
				
					
				<v-row style="margin: auto">
					<v-col cols="8" lg="8" md="8">
						<s-text label="N° Lote Produccion" v-model="RcfID">

						</s-text>
					</v-col>
					<v-col cols="4" md="4" lg="4" v-if="this.$fun.getSecurity().IsLevelAdmin">
						<s-switch label="Abast. Manual" v-model="Optional"></s-switch>
					</v-col>
				</v-row>
			</v-card>

			<v-card flat style="margin-top: -8px; margin-bottom: 5px;">
				<v-row style="margin: auto" justify="center">
					<v-col cols="6" lg="3" md="3">
						<s-select-definition
							:def="1257"
							label="Calibradora"
							style=" border-radius: 8px;"
							v-model="TypeCalibrator"
						/>
					</v-col>
					<v-col cols="6" lg="3" md="3">
						<s-select-definition
							label="Tina"
							:def="1258"
							v-model="TypeTub"
						/>
					</v-col>
					<v-col cols="12" lg="3" md="3">
						<s-text
							label="Cantidad"
							v-model="LcsAmountSupply"
							outlined
							number
							autofocus
						/>
					</v-col>
				</v-row>
			</v-card>

			<v-card>
				<v-btn color="info" @click="saveOptional()">Grabar</v-btn>
			</v-card>



		</v-card>

		<v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>

	
	</div>
</template>

<script>
	import _sLotProdCalibratedSupplyService from "../../../services/FreshProduction/PrfLotProdCalibratedSupplyService";
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";

	export default {
		components: {},
		data: () => ({
			Optional: 0,
			TypeCalibrator: 0,
			TypeTub: 0,
			LcsAmountSupply: 0,
			AmountJabasWeigh: 0,
			filter: {
				cDateInitial: "",
				cDateFin: "",
				filterAll: 0,
				PrfID: 0,
			},
			config: {
				service: _sLotProdCalibratedSupplyService,
				model: {
					RcfID: "ID",
					Options: "",
					/* TypeCalibrator: "",
					TypeTub: "", */
					LcsAmountSupply: "",
				},

				headers: [
					{ text: "Por Abast.", value: "Options", width: 100 },
					/* { text: "Calibradora.", value: "TypeCalibrator", width: 150 },
					{ text: "Tina", value: "TypeTub", width: 150 }, */
					/* { text: "Cantidad", value: "LcsAmountSupply", width: 100 }, */
					{
						text: "Cantidad Abastecida",
						value: "LcsAmountSupplyReal",
						width: 100,
					},
					{ text: "Nro. Lote.", value: "RcfID", width: 100 },
					{ text: "Tipo Servicio", value: "TypeServiceName", width: 100 },
					/* { text: "Productor", value: "PrdCardName", width: 260 }, */
					{ text: "Fundo", value: "FagName", width: 260 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
					{ text: "Variedad", value: "VrtName", width: 160 },
					{ text: "Procedencia", value: "TypeOriginName", width: 160 },
				],
			},
			configScann: {},
			WpbID: 0,
			numberPallet: 0,
			selected: {},
			RcfID: 0,
			colorSuccess: "success",
			processing:false,
			messageProcessing: "",
		}),
		methods: {
			rowSelected(val) {
				if (val.length > 0) {
					this.AmountJabasWeigh = val[0].AmountJabasWeigh - val[0].LcsAmountSupplyReal;
					this.selected = val[0];
					console.log("rowSelected", val);
					this.clickInitial(this.selected)
				}
			},

			saveOptional(){

				if(this.RcfID == 0){
					this.$fun.alert(
							"Ingrese N° Lote de Produccion",
							"warning",
						);
					return;
				}

				if (this.LcsAmountSupply <= 0) {
						this.$fun.alert(
							"La Cantidad a abastecer debe ser mayor a 0",
							"warning",
						);
						return;
				}



				let items = {}
				items.RcfID = this.RcfID
				items.UsrCreateID = this.$fun.getUserID();
				items.SecStatus = 1
				items.TypeCalibrator = this.TypeCalibrator;
				items.TypeTub = this.TypeTub;
				items.LcsAmountSupply = this.LcsAmountSupply;
				items.LcsFlag = 2 //ABASTECER MANUAL
				items.LcsState = 2;
				items.LcsHourInitial = this.$fun.getHour();
				items.LcsHourFinal = this.$fun.getHour();

				this.$fun.alert("Esta seguro de Abastecer al Lote Nro: ? " + this.RcfID, "question").then(val => {
					if (val.value) {
						_sLotProdCalibratedSupplyService
							.save(items, this.$fun.getUserID())
							.then(r => {
								if (r.status == 200) {
									this.$fun.alert(
										"Abastecido correctamente",
										"success",
									);

									this.LcsAmountSupply = 0
								}
							});
					}
				});


				console.log(items);
			},	

			clickInitial(items) {
				var message = "";
				items.UsrCreateID = this.$fun.getUserID();
				items.TypeCalibrator = this.TypeCalibrator;
				items.TypeReception = 1;
				items.TypeTub = this.TypeTub;
				items.WpbID = this.WpbID;
				items.WpbNumberPallet = this.numberPallet;
				items.LcsAmountSupply = this.LcsAmountSupply;
				items.LcsFlag = 1 //ABASTECER POR QR

				if (items.LcsID == 0) {
					items.LcsState = 1;
					items.LcsHourInitial = this.$fun.getHour();

					var jabasFaltante =
						items.AmountJabasWeigh - items.LcsAmountSupplyReal;

					if (jabasFaltante < items.LcsAmountSupply) {
						this.$fun.alert(
							"EL LOTE ESTA  ABASTECIDO EN SU TOTALIDAD, la Cantidad de Fruta (" +
								items.AmountJabasWeigh +
								") recepcionada. Fruta restante => (" +
								jabasFaltante +
								"). .",
							"success",
						);
						return;
					}

					if (jabasFaltante == 0) {
						this.$fun.alert(
							"Ya no se puede abastecer saldo de Jabas es 0",
							"warning",
						);
						return;
					}

					if (items.LcsAmountSupply <= 0) {
						this.$fun.alert(
							"La Cantidad a abastecer debe ser mayor a 0",
							"warning",
						);
						return;
					}

					if (items.AmountJabasWeigh < items.LcsAmountSupply) {
						this.$fun.alert(
							"La Cantidad a abastecer supera la cantidad recepcionada en Frescos",
							"warning",
						);
						return;
					}
					/* if(this.TypeCalibrator !== this.selected.TypeCrop){
						this.$fun.alert(
							"La Calibrado seleccionada es diferente al Tipo de Cultivo de Lote de Producción",
							"warning",
						);
						return;
					} */

					message =
						"Esta seguro de Abastecer al Lote Nro: ? " + items.RcfID;
				 /* else {
					items.LcsState = 2;
					items.LcsHourFinal = this.$fun.getHour();
					message =
						"Esta seguro de terminar abastecimiento al Lote Nro: ? " +
						items.RcfID;
				} */

					/* console.log("items", items);
					this.$fun.alert(message, "question").then(val => {
						if (val.value) { */
							this.processing = true;
							_sLotProdCalibratedSupplyService
								.save(items, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.processing = false;
										this.$fun.alert(
											"Actualizado correctamente",
											"success",
										);
										this.$refs.gridCalibratedSupply.refresh();
										this.colorSuccess = "error"
									}
								},
								(e) => {
									this.processing = false;
								});
						/* }
					}); */

				}

				console.log("Guardar", items);
			},

			onValue(val) {
				if (val == "Sin datos" || isNaN(val)) {
					//this.$fun.alert("El QR Leido no contiene datos", "warning");
					var res2 = val.split(",", 24);
					this.filter.PrfID = parseInt(res2[0]);
					this.colorSuccess = "success"
					
					
				} else {
					this.filter.PrfID = val;
					
					this.colorSuccess = "success"
				}
			},

			onData(val) {
				if (val === undefined) {
					console.log("ingreso manual");
				} else {
					var res = val.split(",", 24);
					this.WpbID = parseInt(res[1]);
					this.LcsAmountSupply = parseInt(res[2]);
					this.numberPallet = parseInt(res[3]);

					this.filter.WpbID = this.WpbID;
					this.filter.PrfID = parseInt(res[0]);
					


					//

					console.log("onData", res);
				}
			},
		},
	};
</script>
